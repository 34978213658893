import React from "react";

import JobInfoMobile from "../../components/JobInfoMobile";
import JobHeader from "./JobHeader";
import TileContact from "../../components/TileContact";
import TileButton from "../../components/TileButton";
import { RichText } from "../../components/RichText/RichText";
import { YoutubeVideo } from "../YoutubeVideo/YoutubeVideo";
import H2 from "../../components/RichText/H2";
import Paragraph from "../../components/RichText/Paragraph";
import nbsp from "../../utils/nbsp";

import { Box, Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import { recruiterPhoto } from "../../utils/contentPhoto";
import { Markdown } from "react-showdown";

const JobPostContent = (props) => {
  const { post, jobsUrl, postData, regions, ...rest } = props;

  return (
    <>
      <JobHeader
        post={post}
        postData={postData}
        siteTitle={"cms"}
        jobsUrl={jobsUrl}
        regions={regions}
      />

      <JobInfoMobile
        post={post}
        jobsUrl={jobsUrl}
        display={["flex", null, "none", null, null]}
      />

      <Box
        width="100%"
        backgroundColor="#ffffff"
        px="32px"
        pt={["40px", null, "64px", "80px"]}
        pb={["40px", null, "64px", "102px"]}
      >
        <Box
          width="100%"
          maxWidth={[null, null, "688px", null, null]}
          mx="auto"
        >
          <RichText content={post.richtext || ""} />
        </Box>
        {post.youtubeVideo && post.youtubeVideoName && (
          <Box
            width="100%"
            maxWidth={[null, null, "688px", null, null]}
            mx="auto"
            // mt={["10px", null, null, "30px", null]}
          >
            <H2>Poslechni si víc</H2>
            <Paragraph><strong>{nbsp(post.youtubeVideoName)}</strong></Paragraph>
            <Box width="100%" mt={["20px", null, null, "32px", null]}>
              <YoutubeVideo youtubeVideo={post.youtubeVideo} />
            </Box>
          </Box>
        )}
        {post?.bottomContent && (
          <Box
            width="100%"
            maxWidth={[null, null, "688px", null, null]}
            mx="auto"
          >
              <Paragraph>
                <Markdown markup={post.bottomContent} />
              </Paragraph>
          </Box>
        )}
      </Box>

      <Flex
        as="section"
        flexDirection={["column-reverse", null, "row", null, null]}
      >
        {postData.recruiter && (
          <TileContact
            image={recruiterPhoto(postData.recruiter?.frontmatter.name)}
            name={postData.recruiter.frontmatter.name}
            phone={postData.recruiter.frontmatter.phone}
            email={postData.recruiter.frontmatter.email}
            bg="EYOffBlack.default"
            width={["100%", null, "50%", null, null]}
          />
        )}

        <TileButton
          href={jobsUrl}
          width={["100%", null, "50%", null, null]}
          tileColor="light"
          tileOrientation="right"
          as={"a"}
          id="mamZajemTile"
        >
          <Flex flexDirection="column" alignItems="flex-end">
            <Text
              fontSize={["18px", null, "5", null, null]}
              color="EYOffBlack.tile"
            >
              Mám zájem o tuhle pozici
            </Text>
            <Text
              fontSize={[1, null, 2, null, null]}
              fontWeight="300"
              color="EYOffBlack.tile"
              opacity=".7"
              mt={["8px", null, "24px", null, null]}
            >
              Vyplň formulář, pošli CV a začni svou cestu s EY.
            </Text>
          </Flex>
        </TileButton>
      </Flex>
    </>
  );
};

export default JobPostContent;
