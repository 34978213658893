import React from "react";

import { Container, Wrapper, Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Button } from "../../style/Button";
import { Salary } from "../../style/Salary";
import { formatContractType } from "../helperFunctions";
import { Image } from "../../style/Image";

const findTeamLogo = post => {
  if (post.showParthenonLogo) {
    return require("../../images/logo-ey-parthenon.svg");
  }
  return "";
};

const Row = ({ label, value, isLast }) => (
  <Flex
    width="100%"
    minHeight="56px"
    borderBottom={isLast ? "0" : "1px solid"}
    borderColor="white.15"
  >
    <Flex width="85px" alignItems="center">
      <Text
        color="EYGrey02.default"
        css={{ textTransform: "uppercase" }}
        fontSize="0"
        fontWeight="light"
      >
        {label}
      </Text>
    </Flex>
    <Flex alignItems="center">
      <Text color="white.100" fontSize="2" fontWeight="light">
        {value}
      </Text>
    </Flex>
  </Flex>
);

const TeamRow = ({ label, value, isLast, logoPath }) => (
  <Wrapper>
    <Flex
      width="100%"
      minHeight="56px"
      borderBottom={isLast || logoPath ? "0" : "1px solid"}
      borderColor="white.15"
    >
      <Flex width="85px" alignItems="center">
        <Text
          color="EYGrey02.default"
          css={{ textTransform: "uppercase" }}
          fontSize="0"
          fontWeight="light"
        >
          {label}
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Text color="white.100" fontSize="2" fontWeight="light">
          {value}
        </Text>
      </Flex>
    </Flex>
    {logoPath && (
      <Flex
        width="100%"
        borderBottom={isLast ? "0" : "1px solid"}
        borderColor="white.15"
      >
        <Flex pl="85px" pb="15px" mt="-8px">
          <Image src={logoPath} display="block" />
        </Flex>
      </Flex>
    )}
  </Wrapper>
);

const JobInfoMobile = props => (
  <Wrapper as="section" bg="EYOffBlack.default" {...props}>
    <Container
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      mt="20px"
      mb="30px"
      position="relative"
    >
      <Row label="Oddělení" value={props.post.jobDepartment} />
      {props.post.jobTeam && (
        <TeamRow
          label="Tým"
          value={props.post.jobTeam}
          logoPath={
            props.post.showParthenonLogo
              ? findTeamLogo(props.post)
              : ""
          }
        />
      )}
      <Row
        label="Kolik si vyděláš"
        value={
          <Salary>
            <Text fontSize="4" color="EYYellow.default" fontWeight="bold">
              {props.post.jobSalary}
            </Text>
          </Salary>
        }
      />
      <Row label="Lokalita" value={props.post.jobLocality} />
      <Row
        label="Typ úvazku"
        value={formatContractType(props.post.jobContractType)}
        isLast={true}
      />
      <Button as="a" href={props.jobsUrl} px="25px" mt="25px">
        Chci do EY
      </Button>
    </Container>
  </Wrapper>
);

export default JobInfoMobile;
