import React from "react";

import Button from "../../components/Button";

import { Image } from "../../style/Image";
import { Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import AtmoskopImage from "../../images/atmoskop.svg";

const Atmoskop = () => {
    return (
        <Flex
            width="100%"
            minHeight="320px"
            css="@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { height: 320px}"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            backgroundColor="EYOffWhite"
            px="4.5%"
        >
            <Image src={AtmoskopImage} />
            <Text
                my="30px"
                textAlign="center"
                color="EYOffBlack.default"
                fontSize="2"
                fontWeight="light"
            >
                Podívej se, jak hodnotí EY naši současní
                <br />a bývalí zaměstnanci.
            </Text>
            <Button
                href="https://www.atmoskop.cz/nazory-na-skupinu/15-ey-ernst-young"
                variant="outlineBlack"
            >
                Chci vědět víc
            </Button>
        </Flex>
    );
};

export default Atmoskop;
