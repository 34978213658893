import React, { useEffect, useLayoutEffect, useState } from "react";

import { Image } from "../../style/Image";
import { Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import getStyledLink from "../../style/Link/getStyledLink";
import { theme } from "../../style/theme";
import { smoothScroll } from "../helperFunctions";
import CrossSmall from "../../images/cross-small.svg";
import ArrowLight from "../../images/arrow-light.svg";
import ArrowDark from "../../images/arrow-dark.svg";

const HIDDEN = "hidden";
const VISIBLE = "visible";

const PageNavLink = ({ label, link, offset = false }) => {
  const StyledLink = getStyledLink("a");

  return (
    <StyledLink
      as="a"
      title={label}
      href={link}
      display="flex"
      alignItems="center"
      fontSize="0"
      my="8px"
      color="EYOffBlack.default"
      onClick={e => smoothScroll(e, link, offset)}
    >
      <Image
        src={ArrowDark}
        opacity="0.3"
        height="8px"
        mx="16px"
      />
      {label}
    </StyledLink>
  );
};

const PageNav = props => {
  const [displayState, setDisplayState] = useState(HIDDEN);
  const [isOpen, setIsOpen] = useState(true);
  const [buttonRef, setButtonRef] = useState(null);

  useLayoutEffect(() => {
    let button = document.getElementById("mamZajemTile");
    setButtonRef(button);

    if (!button) {
      setDisplayState(VISIBLE);
    }
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (
        buttonRef &&
        buttonRef.getBoundingClientRect().top + buttonRef.offsetHeight > 0
      ) {
        setDisplayState(HIDDEN);
      } else {
        setDisplayState(VISIBLE);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  let rightPos = 0;
  if (displayState === HIDDEN) {
    rightPos = "-400px";
  } else if (isOpen) {
    rightPos = 0;
  } else {
    rightPos = "-200px";
  }

  return (
    <Flex
      backgroundColor="EYOffWhite"
      width="272px"
      position="fixed"
      zIndex="99999"
      top="30%"
      right={rightPos}
      flexDirection="column"
      boxShadow="0 2px 4px rgba(0, 0, 0, 0.5)"
      display={["none", null, null, "flex", null]}
      css={{
        transition: `right ${theme.times.transition}`,
      }}
    >
      <Flex
        width="100%"
        height="79px"
        backgroundColor="EYOffBlack.default"
        alignItems="center"
        justifyContent="space-between"
        color="white.100"
        px="25px"
        flexShrink="0"
      >
        {isOpen && (
          <>
            <Text
              fontWeight="bold"
              fontSize="1"
              color="white.100"
              ml="10px"
              css={{ textTransform: "uppercase" }}
            >
              Kam dál?
            </Text>
            <Image
              src={CrossSmall}
              css={{
                cursor: "pointer",
              }}
              onClick={() => setIsOpen(false)}
            />
          </>
        )}
        {!isOpen && (
          <Image
            src={ArrowLight}
            height="15px"
            css={{
              transform: "rotate(180deg)",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(true)}
          />
        )}
      </Flex>
      {isOpen && (
        <Flex flexDirection="column" height="172px" flexShrink="0" pt="15px">
          <PageNavLink
            label="Proč se přidat k EY?"
            link="#proc-se-pridat-k-ey"
          />
          <PageNavLink
            label="Jak funguje kariérní růst"
            link="#jak-funguje-karierni-rust"
            offset={"nav"}
          />
          <PageNavLink
            label="S jakými firmami spolupracujeme?"
            link="#s-jakymi-firmami-spolupracujeme"
            offset={"nav"}
          />
          <PageNavLink label="Často kladené otázky" link="#faq" />
        </Flex>
      )}
    </Flex>
  );
};

export default PageNav;
