import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const Wrapper = styled.div`
  position: relative;
  padding-top: 48px;
  padding-bottom: 34px;
`;

const _H3 = styled.h3`
  font-size: 24px;
  line-height: 32px;
  color: ${theme.colors.EYOffBlack.default};
  font-weight: 400;
  margin: 0;
`;

const Line = styled.div`
  width: 243px;
  height: 4px;
  position: absolute;
  bottom: 15px;
  left: 0;
  background-color: ${theme.colors.EYYellow.default};
`;

const Dot1 = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  bottom: 15px;
  left: -8px;
  background-color: ${theme.colors.EYYellow.default};
`;

const Dot2 = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  bottom: 15px;
  left: -16px;
  background-color: ${theme.colors.EYYellow.default};
`;

const Dot3 = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  bottom: 15px;
  left: 24px;
  background-color: ${theme.colors.EYYellow.default};
`;

const H3 = ({ children }) => (
  <Wrapper>
    <_H3>{children}</_H3>
    <Line />
    <Dot1 />
    <Dot2 />
    <Dot3 />
  </Wrapper>
);

export default H3;
