import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const _OrderedList = styled.ol`
  width: 100%;
  counter-reset: list;
  margin: 0;
  padding: 16px 0 30px 0;

  & > li {
    width: 100%;
    display: flex;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.EYOffBlack.default};
    font-weight: 300;
    margin: 32px 0 0 0;

    &:first-child {
      margin: 0;
    }

    &:before {
      text-align: center;
      counter-increment: list;
      content: counter(list) ".";
      display: block;
      flex-shrink: 0;
      line-height: 16px;
      font-weight: 400;
      width: 30px;
      height: 30px;
      padding: 4px;
      border-bottom: 4px solid ${theme.colors.EYYellow.default};
      margin-right: 16px;
    }

    & > ul,
    & > ol {
      padding: 24px 0 0 48px !important;
    }
  }
`;

const OrderedList = ({ children }) => <_OrderedList>{children}</_OrderedList>;

export default OrderedList;
