import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const _Link = styled.a`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.EYOffBlack.default};
  font-weight: 400;
  margin: 0;
  padding: 14px 0;

  &:hover {
    text-decoration: none;
  }
`;

const Link = ({ children, href, target }) => (
  <_Link href={href} target={target || undefined}>
    {children}
  </_Link>
);

export default Link;
