import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const _ListItem = styled.li`
  width: 100%;
  display: block;
`;

const ListItemInner = styled.div``;

const UnorderedList = ({ children }) => (
  <_ListItem>
    <ListItemInner>{children}</ListItemInner>
  </_ListItem>
);

export default UnorderedList;
