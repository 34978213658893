import React, { Fragment } from "react";
import parse from "html-react-parser";
import { domToReact } from "html-react-parser";
import { v4 } from "uuid";
import nbsp from "../../utils/nbsp";

import H2 from "./H2";
import H3 from "./H3";
import H4 from "./H4";
import Paragraph from "./Paragraph";
import Link from "./Link";
import OrderedList from "./OrderedList";
import UnorderedList from "./UnorderedList";
import ListItem from "./ListItem";

const parserOptions = new (class {
  replace(node) {
    if (node.type === "tag") {
      const domNode = node;
      switch (domNode.name) {
        case "a": {
          const linkParams = domNode.attribs;
          if (linkParams && domNode.children) {
            delete linkParams.style;
            return (
              <Link key={v4()} {...linkParams}>
                {domToReact(domNode.children, parserOptions)}
              </Link>
            );
          } else {
            return <Fragment key={v4()} />;
          }
        }

        case "h2":
          if (domNode.children) {
            return (
              <H2 key={v4()} tag={domNode.name}>
                {domToReact(domNode.children, parserOptions)}
              </H2>
            );
          } else {
            return <Fragment key={v4()} />;
          }

        case "h3":
          if (domNode.children) {
            return (
              <H3 key={v4()} tag={domNode.name}>
                {domToReact(domNode.children, parserOptions)}
              </H3>
            );
          } else {
            return <Fragment key={v4()} />;
          }

        case "h4":
          if (domNode.children) {
            return (
              <H4 key={v4()} tag={domNode.name}>
                {domToReact(domNode.children, parserOptions)}
              </H4>
            );
          } else {
            return <Fragment key={v4()} />;
          }

        case "p":
          if (domNode.children && domNode.children.length > 0) {
            return (
              <Paragraph key={v4()}>
                {domToReact(domNode.children, parserOptions)}
              </Paragraph>
            );
          } else {
            return <Fragment key={v4()} />;
          }

        case "ul":
          if (domNode.children && domNode.children.length > 0) {
            return (
              <UnorderedList key={v4()} tag="ul">
                {domToReact(domNode.children, parserOptions)}
              </UnorderedList>
            );
          } else {
            return <Fragment key={v4()} />;
          }

        case "ol":
          if (domNode.children && domNode.children.length > 0) {
            return (
              <OrderedList key={v4()} tag="ol">
                {domToReact(domNode.children, parserOptions)}
              </OrderedList>
            );
          } else {
            return <Fragment key={v4()} />;
          }
        case "li":
          if (domNode.children && domNode.children.length > 0) {
            return (
              <ListItem key={v4()}>
                {domToReact(domNode.children, parserOptions)}
              </ListItem>
            );
          } else {
            return <Fragment key={v4()} />;
          }

        case "b":
        case "strong": {
          return (
            <strong key={v4()}>
              {domToReact(domNode.children || [], parserOptions)}
            </strong>
          );
        }

        case "i": {
          return (
            <em key={v4()}>
              {domToReact(domNode.children || [], parserOptions)}
            </em>
          );
        }
      }
    }

    if (node.type === "text") {
      const domNode = node;
      const _value = nbsp(domNode.data);

      if (domNode?.parent?.name === "ul" || domNode?.parent?.name === "ol") {
        if (_value.length === 0 || _value === " " || _value === "\n")
          return null;
      }

      if (domNode?.parent?.name === "li") {
        return <span>{_value}</span>;
      }

      return <>{_value}</>;
    }

    return;
  }
})();

const RichText = ({ content }) => <>{parse(content, parserOptions)}</>;

export { RichText };
