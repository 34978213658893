import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";

import JobPostContent from "../components/JobPostContent/JobPostContent";
import JobPostBubbles from "../components/JobPostContent/JobPostBubbles";
import JobWhy from "../components/JobPostContent/JobWhy";
import TopicLinks from "../components/TopicLinks";
import Atmoskop from "../components/Atmoskop";
import Faq from "../components/Faq";
import PageNav from "../components/PageNav";
import usePageView from "../utils/usePageView";
const URL = require("../constants").URL;

const JobPostTemplate = ({ data, ...props }) => {
  const jobPostBubblesData = [
    {
      id: "jak-funguje-karierni-rust",
      left: true,
      light: true,
      wide: false,
      description: "Zajímá tě, jak u nás funguje kariérní růst?",
      name: "Sandra Jirásková",
      position: "Recruitment managerka",
      image: data.jobPostBubblesImage1,
      button: {
        label: "Jasně",
        href: `${URL.about}#karierni-rust`,
      }
    },
    {
      left: false,
      light: true,
      wide: false,
      description: "Přemýšlíš, jak u nás vypadá běžný pracovní den?",
      name: "Jana Solarová",
      position: "Managerka",
      department: "Audit",
      image: data.jobPostBubblesImage2,
      button: {
        label: "Přesně tak",
        href: `${URL.blog}/jak-v-ey-probiha-bezny-pracovni-den/`,
      }
    },
    // {
    //   id: "s-jakymi-firmami-spolupracujeme",
    //   left: true,
    //   light: true,
    //   wide: false,
    //   description: "Chceš vědět, se kterými firmami spolupracujeme?",
    //   name: "Petr Knap",
    //   position: "Partner",
    //   department: "Business Consulting",
    //   image: data.jobPostBubblesImage3,
    //   button: {
    //     label: "Ano, chci",
    //     href: `${URL.about}#spoluprace`,
    //   }
    // },
    {
      left: true,
      light: true,
      wide: false,
      description: "Chceš vědět, jaké možnosti vzdělávání našim kolegům nabízíme?",
      name: "Renata Fuksová",
      position: "HR Managerka",
      image: data.jobPostBubblesImage4,
      button: {
        label: "Ano, chci",
        href: `${URL.education}`,
      }
    }
  ];

  usePageView(props.location.pathname, "Volné pozice", "Pracovní pozice");

  const post = data.mdx.frontmatter;
  const postData = data.mdx.frontmatter;
  const faq = data.allMdx.nodes;
  const regions = data.allRegions.nodes;
  const siteTitle = data.site.siteMetadata.title;
  const jobsUrl = `https://ey.jobs.cz/detail/form/?id=G2-${props.pageContext.slug.replace(
    /\//gi,
    ""
  )}-aden_brand0&rps=186`;

  return (
    <Layout location={props.location} title={siteTitle}>
    <SEO title={post.title} description={post.description || post.excerpt} ogimage={postData.jobHeaderImageFile.publicURL} />
      <PageNav />
      <JobPostContent
        post={post}
        siteTitle={siteTitle}
        jobsUrl={jobsUrl}
        postData={postData}
        regions={regions}
      />
      <TopicLinks
        as="section"
        headingSize="5"
        items={[
          {
            heading: "Napiš poutavé CV",
            link: `${URL.blog}/napis-poutave-cv-ktere-te-dostane-na-pohovor-v-ey/`,
            image: data.topicLinksImage1,
          },
          {
            heading: "4 rady, jak zvládnout pohovor do EY",
            link: `${URL.blog}/60-4-rady-jak-zvladnout-pohovor-do-ey/`,
            image: data.topicLinksImage2,
          },
        ]}
      >
        Možná ti to přijde vhod
      </TopicLinks>
      <JobWhy image={data.jobWhyImage}/>
      <JobPostBubbles bubbles={jobPostBubblesData}/>
      <Atmoskop />
      <Faq faqs={faq} />
    </Layout>
  );
};

export default JobPostTemplate;

export const pageQuery = graphql`
  query JobPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allRegions: allMdx(filter: {frontmatter: {templateKey: {eq: "region"}}}) {
      nodes {
        frontmatter {
          title
          id
        }
      }
    }
     allMdx (filter:  {frontmatter: { templateKey: { eq: "faq" } } })
  	 {
        nodes {
          frontmatter {
            title
            answer
            link
          }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        jobDepartment
        jobSalary
        jobContractType
        jobLocality
        richtext
        jobTeam
        jobHeaderImageFile {
          childImageSharp {
            gatsbyImageData(width: 1920,quality: 90)
          }
          publicURL
        }
        recruiter {
          frontmatter {
            name
            phone
            email
          }
        }
        region
        showParthenonLogo
        youtubeVideoName
        youtubeVideo
        bottomContent
      }
    }
    topicLinksImage1: file(relativePath: {eq: "napis-poutave-cv.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 1440,quality: 90)
      }
    }
    topicLinksImage2: file(relativePath: {eq: "4-rady-jak-na-vyberko-do-ey.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920,quality: 90)
      }
    }
    jobWhyImage: file(relativePath: {eq: "whyJoinBg.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920,quality: 90)
      }
    }
    jobPostBubblesImage1: file(relativePath: {eq: "recruiters/SandraJiraskova.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
      }
    }
    jobPostBubblesImage2: file(relativePath: {eq: "jana-solarova-small.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
      }
    }
    jobPostBubblesImage3: file(relativePath: {eq: "petr-knap-small.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
      }
    }
    jobPostBubblesImage4: file(relativePath: {eq: "recruiters/RenataFuksova.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
      }
    }
  }
`;
