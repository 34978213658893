import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  background,
  border,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

import { Link as GatsbyLink } from "gatsby";

const FaqTile = styled.a`
  display: flex;
  text-decoration: none;
  position: relative;

  ${space}
  ${flexbox}
  ${color}
  ${layout}
  ${typography}
  ${background}
  ${border}

  & > p {
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 6;
  }

  & > p, & > span {
    overflow: hidden;
    transition: max-height ${themeGet("times.transition")};
  }

  & > img {
    transition: opacity ${themeGet("times.transition")};
  }

  div > p {
    white-space: nowrap;
    overflow: hidden;
    transition: max-width ${themeGet("times.transition")};
  }

  div > img {
    transition: margin-left ${themeGet("times.transition")};
  }

  &:hover {
    & > p {
      max-height: 300px;
    }

    div > p {
      max-width: 200px;
    }

    div > img {
      margin-left: 17px;
    }
  }

  @media screen and (min-width: ${themeGet(
    "breakpoints.lg"
  )}) and (max-width: ${themeGet("breakpoints.xl")}) {
    &:nth-child(even) {
      margin-left: 10px;
    }

    &:nth-child(odd) {
      margin-right: 10px;
    }
  }
`;

FaqTile.displayName = `FaqTile`;

FaqTile.defaultProps = {
  flexDirection: "column",
  border: "1px solid",
  borderColor: "EYGrey01",
  width: "389px",
  height: "310px",
  p: "30px",
  mt: "24px",
};

FaqTile.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.background,
  ...propTypes.border,
};

export { FaqTile };
