const VIDEO_URL = "https://www.youtube.com/watch?v=";
const SHARE_URL = "https://youtu.be/";

export const getYoutubeVideoId = (e) => {
  if (typeof e !== "string") return null;

  if (e.includes(VIDEO_URL)) {
    const part = e.slice(VIDEO_URL.length);
    return part.split("&")[0];
  }

  if (e.includes(SHARE_URL)) {
    const part = e.slice(SHARE_URL.length);
    return part.split("&")[0];
  }

  return e;
};
