import styled from "styled-components";
import { space, color, layout, typography } from "styled-system";
import propTypes from "@styled-system/prop-types";

import themeGet from "@styled-system/theme-get";

const List = styled.ul`
  list-style: none inside;
  
  li {
    position: relative;
    padding-left: 20px;
    font-size: ${themeGet("fontSizes.2")};
  
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: ${themeGet("colors.EYYellow.default")};
    }
  }

  ${space}
  ${color}
  ${layout}
  ${typography}
`;

List.displayName = `List`;

List.defaultProps = {
  mx: 0,
  my: "40px",
};

List.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
};

export { List };
