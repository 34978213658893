import styled from "styled-components";

const RecruiterPhoneNumberStyle = styled.span`
  width: 100%;
  display: block;
  a + a {
    margin-left: 15px;
  }
  svg {
    margin-bottom: -10px;
  }
`;

RecruiterPhoneNumberStyle.defaultProps = {};

export { RecruiterPhoneNumberStyle };
