import styled from "styled-components";

import { Flex } from "../Grid";

const Salary = styled(Flex)`
  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;

    p:nth-of-type(1) {
      line-height: 100%;
    }

    p:nth-of-type(2) {
      margin-left: 0;
    }
  }
`;

Salary.displayName = `Salary`;

Salary.defaultProps = {
  alignItems: "center",
  flexDirection: "row",
};

export { Salary };
