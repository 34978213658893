import React from "react";
import { WhatsAppIcon } from "../Icons/WhatsAppIcon";
import { RecruiterPhoneNumberStyle } from "../../style/RecruiterPhoneNumber";
import getStyledLink from "../../style/Link/getStyledLink";
const StyledLink = getStyledLink("a");

const RecruiterPhoneNumber = props => {
  const phoneNumberRaw = props.phoneNumber
    ? props.phoneNumber.replace(/ /g, "")
    : "";

  return (
    props.phoneNumber && (
      <RecruiterPhoneNumberStyle>
        <StyledLink href={`tel:${phoneNumberRaw}`} {...props}>
          {props.phoneNumber}
        </StyledLink>
        <a
          href={`https://api.whatsapp.com/send?phone=${phoneNumberRaw.replace(
            "+",
            ""
          )}`}
          target="_blank"
          title="WhatsApp"
          rel="noreferrer"
        >
          <WhatsAppIcon width="32" height="32" />
        </a>
      </RecruiterPhoneNumberStyle>
    )
  );
};

export default RecruiterPhoneNumber;
