import React from "react";

export const PlayIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" {...props}>
    <path
      id="Polygon_1"
      d="M14,0,28,24H0Z"
      transform="translate(24) rotate(90)"
      fill="currentColor"
    />
  </svg>
);
