import React, { useState } from "react";

import { FaqTile as FaqTileStyle } from "../../style/FaqTile";
import { Heading } from "../../style/Heading";
import { Box } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PlusYellow from "../../images/plus-yellow.svg";

const FaqTile = props => {
  const { data } = props;
  const [isActive, setActive] = useState(false);

  return (
    <FaqTileStyle
      as="div"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      {...props}
    >
      <Heading
        as="h3"
        color="white.100"
        fontWeight="light"
        fontSize={["18px", null, null, "4", null]}
      >
        {data.title}
      </Heading>
      <Text
        as="span"
        color="white.100"
        fontSize="2"
        fontWeight="300"
        mt={["15px", null, null, "25px", null]}
        maxHeight={isActive ? "300px" : 0}
      >
        <Box
          as="span"
          display="block"
          height="3px"
          mb={["15px", null, null, "30px", null]}
          mt={[0, null, null, "10px", null]}
          width="65px"
          bg="EYYellow.default"
        />
        {data.answer.slice(0, 204)}
      </Text>
      <Image
        src={PlusYellow}
        position="absolute"
        bottom="30px"
        left="30px"
        height="25px"
        ml="0"
        opacity={isActive ? "0" : "1"}
      />
    </FaqTileStyle>
  );
};

export default FaqTile;
