import React from "react";

import { Flex } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Image } from "../../style/Image";
import { Text } from "../../style/Text";
import getStyledLink from "../../style/Link/getStyledLink";
import RecruiterPhoneNumber from "../RecruiterPhoneNumber";
const Link = getStyledLink("a");

const TileContact = props => {
  const { image, name, phone, email } = props;

  return (
    <Flex
      px={["30px", null, null, null, "0"]}
      py={["60px", null, "30px", null, "0"]}
      {...props}
    >
      <Flex
        width="100%"
        flexDirection={["column", null, null, null, "row"]}
        justifyContent="center"
        alignItems={["flex-start", null, null, null, "center"]}
      >
        <Heading
          as="h3"
          fontWeight="light"
          fontSize={["4", null, null, "5", null]}
          color="white.100"
          lineHeight="2"
          mb={["30px", null, null, null, "0"]}
          maxWidth={["auto", null, null, null, "185px"]}
        >
          S kým budeš v kontaktu?
        </Heading>
        <Flex alignItems="flex-end">
          <Image
            src={image}
            width={["66px", "88px", null, null, null]}
            height={["66px", "88px", null, null, null]}
            ml={["0", null, null, null, "40px"]}
            mr="25px"
            borderRadius="44px"
          />
          <Flex flexDirection="column">
            <Text
              css={{ textTransform: "uppercase" }}
              color="white.50"
              fontSize="1"
            >
              recruiter
            </Text>
            <Text
              color="white.100"
              fontWeight="normal"
              lineHeight="2"
              my="13px"
            >
              {name}
            </Text>
            <RecruiterPhoneNumber
              phoneNumber={phone}
              color="white.100"
              fontWeight="light"
              lineHeight="2"
              mb="2px"
              fontSize={["1", "2", null, null, null]}
              white={true}
            />
            <Link
              href={`mailto:${email}`}
              color="white.100"
              fontWeight="light"
              lineHeight="4"
              fontSize={["1", "2", null, null, null]}
              white={true}
              mt="4px"
            >
              {email}
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TileContact;
