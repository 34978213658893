import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const _H4 = styled.h4`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.EYOffBlack.default};
  font-weight: 400;
  margin: 0;
  padding: 13px 0;
`;

const H4 = ({ children }) => <_H4>{children}</_H4>;

export default H4;
