import React from "react";
import { theme } from "../../style/theme";
import Header from "../../components/Header";
import styled from "styled-components";

import TriangleSharp from "../../style/Header/triangleSharp";
import { Heading } from "../../style/Heading";
import { Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Button } from "../../style/Button";
import { formatContractType } from "../helperFunctions";
import { Image } from "../../style/Image";
import ParthenonLogo from "../../images/logo-ey-parthenon.png";

const JobHeader = (props) => {
  const { post, jobsUrl, postData, regions } = props;

  const region = regions.find((r) => String(r.frontmatter.id) === post.region);

  return (
    <Header
      bgImage={
        post?.jobHeaderImageFile?.childImageSharp
          ? post?.jobHeaderImageFile
          : post?.jobHeaderImageFile?.publicURL
      }
      height={["450px", null, "638px", null, null]}
      bottomComponents={
        <TriangleSharp
          backgroundColor={["#2e2e38", null, "#ffffff", null, null]}
        />
      }
      shadowAfter={true}
      css={{
        ":after": {
          backgroundImage:
            "linear-gradient(4.2deg, rgba(46, 46, 56, 0.7) 0%, rgba(91, 91, 105, 0) 100%)",
        },
      }}
    >
      <Flex height="100%" width="100%" justifyContent="space-between">
        <Flex
          height="100%"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Heading
            as="h1"
            color="white.100"
            mb={[3, 4, null, null, 4]}
            fontSize={[5, null, 6, 7, null]}
            fontWeight="light"
            lineHeight="3"
            maxWidth={["auto", null, "500px", null, null]}
            backgroundPosition="center center"
            position="relative"
            top={["-60px", null, "0", null, null]}
          >
            {post.title}
          </Heading>
          <Flex
            alignItems="center"
            justifyContent="center"
            mb="130px"
            display={["none", null, "flex", null, null]}
          >
            <Flex
              flexDirection="column"
              pr="65px"
              borderRight="1px solid"
              borderColor={
                post.jobTeam || post.showParthenonLogo
                  ? "white.15"
                  : "transparent"
              }
            >
              <Text
                color="white.100"
                fontSize="0"
                fontWeight="300"
                mb="3px"
                css={{ textTransform: "uppercase" }}
              >
                Oddělení
              </Text>
              <Text color="white.100" fontSize="2" fontWeight="300">
                {post.jobDepartment.join(", ")}
              </Text>
            </Flex>
            {post.jobTeam && (
              <Flex
                flexDirection="column"
                pl="65px"
                pr={post.showParthenonLogo ? "65px" : "0"}
                fontWeight="300"
                borderRight="1px solid"
                borderColor={
                  post.showParthenonLogo ? "white.15" : "transparent"
                }
              >
                <Text
                  color="white.100"
                  fontSize="0"
                  fontWeight="300"
                  mb="3px"
                  css={{ textTransform: "uppercase" }}
                >
                  Tým
                </Text>
                <Text color="white.100" fontSize="2" fontWeight="300">
                  {post.jobTeam}
                </Text>
              </Flex>
            )}
            {post.showParthenonLogo && (
              <Flex flexDirection="column" pl="65px">
                <Image src={ParthenonLogo} height="48px" />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="flex-end"
          width="249px"
          mb={["20px", null, null, null, null]}
          display={["none", null, "flex", null, null]}
        >
          <Flex
            as="svg"
            height="25px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 25"
            preserveAspectRatio="none"
          >
            <polygon
              fill={theme.colors.EYYellow.default}
              points="0,25 100,0 100,25"
            />
          </Flex>
          <Flex
            backgroundColor="EYYellow.default"
            height="160px"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            pl="48px"
          >
            <a href="#jak-se-ti-odmenime" css={{ textDecoration: "none" }}>
              <Text
                fontSize="0"
                color="EYOffBlack.default"
                css={{ textTransform: "uppercase" }}
              >
                Kolik si vyděláš
              </Text>

              <Text
                fontSize={post.jobSalary.length > 9 ? 3 : 5}
                fontWeight="bold"
                color="black.100"
                textDecoration="none"
              >
                {post.jobSalary}
              </Text>
            </a>
          </Flex>
          <Flex
            backgroundColor="EYOffBlack.default"
            height="301px"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            pt="30px"
            pb="40px"
            px="48px"
          >
            <Flex
              width="100%"
              borderBottom="1px solid"
              borderColor="white.15"
              flexDirection="column"
            >
              <Text
                color="white.70"
                fontSize="12px"
                mb="5px"
                css={{ textTransform: "uppercase" }}
              >
                Lokalita
              </Text>
              <Text
                color="EYOffWhite"
                fontSize="18px"
                mb="20px"
                fontWeight="normal"
              >
                {post.jobLocality.length > 0
                  ? post.jobLocality
                  : region
                  ? region.frontmatter.title
                  : ""}
              </Text>
            </Flex>
            <Text
              color="white.70"
              fontSize="12px"
              mb="5px"
              mt="auto"
              css={{ textTransform: "uppercase" }}
            >
              Typ úvazku
            </Text>
            <Text
              color="EYOffWhite"
              fontSize={post.jobContractType.length > 1 ? 1 : "18px"}
            >
              {formatContractType(post.jobContractType)}
            </Text>
            <Button
              as="a"
              href={jobsUrl}
              alignSelf="stretch"
              mt="auto"
              px="25px"
              id="mamZajemButton"
            >
              Mám zájem
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Header>
  );
};

export default JobHeader;
