import React from "react";
import Bubble from "../../components/Bubble";
import { Container, Box, Wrapper } from "../../style/Grid";

const JobPostBubbles = props => {
  return (
    <>
      <Wrapper as="section" bg="white.100" overflow="hidden">
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mt={["60px", null, null, "120px", null]}
          mb={["10px", null, null, "100px", null]}
        >
          <Box>
            {props.bubbles.map(bubble => (
              <Bubble
                {...bubble}
              />
            ))}
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

export default JobPostBubbles;
