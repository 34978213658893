import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const Wrapper = styled.div`
  position: relative;
  padding-top: 24px;
  padding-bottom: 42px;
`;

const _H2 = styled.h2`
  font-size: 32px;
  line-height: 40px;
  color: ${theme.colors.EYOffBlack.default};
  font-weight: 400;
  margin: 0;
`;

const Line = styled.div`
  width: 243px;
  height: 8px;
  position: absolute;
  bottom: 18px;
  left: 0;
  background-color: ${theme.colors.EYYellow.default};
`;

const Dot1 = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 18px;
  left: 251px;
  background-color: ${theme.colors.EYYellow.default};
`;

const Dot2 = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 18px;
  left: 267px;
  background-color: ${theme.colors.EYYellow.default};
`;

const Dot3 = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 18px;
  left: 283px;
  background-color: ${theme.colors.EYYellow.default};
`;

const H2 = ({ children }) => (
  <Wrapper>
    <_H2>{children}</_H2>
    <Line />
    <Dot1 />
    <Dot2 />
    <Dot3 />
  </Wrapper>
);

export default H2;
