import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const _Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.EYOffBlack.default};
  font-weight: 300;
  margin: 0;
  padding: 14px 0;

  a {
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.EYOffBlack.default};
    font-weight: 400;
    margin: 0;
    padding: 14px 0;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Paragraph = ({ children }) => <_Paragraph>{children}</_Paragraph>;

export default Paragraph;
