import React from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

import { Container, Wrapper, Flex } from "../../style/Grid";

import { FaqTile as FaqTileStyle } from "../../style/FaqTile";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";

import FaqTile from "../../components/FaqTile";
import ArrowYellow from "../../images/arrow-yellow.svg";

const faqSort = (a, b) => {
  if (
    b.frontmatter.link &&
    b.frontmatter.link.substring(0, 6) === "mailto" &&
    (!a.frontmatter.link || a.frontmatter.link.substring(0, 6) !== "mailto")
  ) {
    return -1;
  }
  if (!a.frontmatter.link) {
    return 0;
  }

  return a.frontmatter.link && a.frontmatter.link.substring(0, 6) === "mailto"
    ? 1
    : 0;
};

const FaqItem = ({ item }) => (
  <>
    {item.frontmatter.link && (
      <FaqTileStyle
        href={item.frontmatter.link}
        css={{ cursor: "pointer" }}
        target={item.frontmatter.link.substring(0, 6) !== "mailto" && "_blank"}
      >
        <Heading
          as="h3"
          color="white.100"
          fontWeight="light"
          fontSize={["18px", null, null, "4", null]}
        >
          {item.frontmatter.title}
        </Heading>
        <Text
          color="EYGrey02.default"
          fontSize="2"
          fontWeight="300"
          mt="25px"
          maxHeight="0"
        >
          {item.frontmatter.answer.slice(0, 204)}
          {item.frontmatter.answer.length >= 204 && "..."}
        </Text>
        <Flex alignItems="center" mt="auto">
          <Text color="EYYellow.default" fontSize="2" maxWidth="0">
            {item.frontmatter.link.substring(0, 6) === "mailto"
              ? item.frontmatter.link.slice(7)
              : "Chci vědět víc"}
          </Text>
          <Image
            src={ArrowYellow}
            height="22"
            ml="0"
          />
        </Flex>
      </FaqTileStyle>
    )}
    {!item.frontmatter.link && <FaqTile data={item.frontmatter}></FaqTile>}
  </>
);

const Faq = props => {
  const { faqs } = props;
  const swiperParams = {
    slidesPerView: "auto",
  };

  return (
    <Wrapper
      id="faq"
      as="section"
      bg="EYOffBlack.default"
      py={["70px", null, null, "120px", null]}
      {...props}
    >
      <Container
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Heading
          as="h2"
          color="white.100"
          fontWeight="light"
          fontSize={["4", null, null, "6", null]}
          mb={["0", null, null, "15px", null]}
        >
          Často kladené otázky
        </Heading>
        <Flex
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent={["center", null, null, null, "space-between"]}
          display={["none", null, null, "flex", null]}
        >
          {faqs.sort(faqSort).map(item => (
            <FaqItem item={item} />
          ))}
        </Flex>
      </Container>

      <Flex
        width="100%"
        pl="4.5%"
        display={["flex", null, null, "none", null]}
        css={`
          .swiper-container {
            width: 100%;
          }
        `}
      >
        <Swiper {...swiperParams}>
          {faqs.sort(faqSort).map(item => (
            <Flex width="288px !important" flexShrink="0">
              <FaqItem item={item} />
            </Flex>
          ))}
        </Swiper>
      </Flex>
    </Wrapper>
  );
};

export default Faq;
