import React from "react";
import { Heading } from "../../style/Heading";
import { Flex } from "../../style/Grid";
import Button from "../../components/Button";
import { List } from "../../style/List";
import { Image } from "../../style/Image";

const URL = require("../../constants").URL;

const JobWhy = props => {
  return (
    <Flex
      as="section"
      flexDirection={["column-reverse", null, null, "row", null]}
      id="proc-se-pridat-k-ey"
    >
      <Flex
        width={["100%", null, null, "50%", null]}
        bg="EYOffBlack.default"
        justifyContent={["center", null, null, null, "flex-end"]}
        mt={["30px", null, null, "0", null]}
        px={["30px", null, null, "0", null]}
        py={["60px", null, null, "0", null]}
      >
        <Flex
          width={["auto", null, null, null, "720px"]}
          height="100%"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          pl={["0", null, null, null, "120px"]}
        >
          <Heading
            as="h2"
            color="white.100"
            fontSize={[4, null, null, 5, 6]}
            fontWeight="300"
          >
            Proč se přidat k EY?
          </Heading>
          <List color="white.100" fontWeight="300">
            <li>Pomůžeme ti mezi odborníky</li>
            <li>Nepřestaneš se učit nové věci</li>
            <li>Budeš v partě lidí, kteří jsou na stejné vlně</li>
            <li>Podíváš se do světa</li>
            <li>Postaráme se o to, aby ti nic nechybělo</li>
          </List>
          <Button href={URL.about}>Chci vědět víc</Button>
        </Flex>
      </Flex>
      <Flex
        width="50%"
        display={["none", null, null, "flex", null]}
        minHeight="648px"
        position="relative"
        >
          {props.image?.childImageSharp && (
            <Image
              image={props.image}
              cover
            />
          )}
      </Flex>
    </Flex>
  );
};

export default JobWhy;
