import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import YTVideo from "react-youtube";
import { getYoutubeVideoId } from "../../utils/getYoutubeVideoId";
import { Image } from "../../style/Image";
import { PlayIcon } from "../Icons/Play";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #2e2e38;

  ${({ defaultView }) =>
    defaultView &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  iframe {
    margin: 0 !important;
  }
`;

const PlayButton = styled.button`
  width: 48px;
  position: relative;
  z-index: 2;
  color: #ffe600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: color ease-in-out 300ms;

  svg {
    width: 100%;
    max-height: 100%;
  }

  @media (pointer: fine) {
    &:hover {
      color: #ffffff;
    }
  }

  @media screen and (min-width: 500px) {
    width: 64px;
  }
`;

const YoutubeVideo = ({ youtubeVideo }) => {
  const ref = useRef(null);

  const [showVideo, setShowVideo] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (ref && ref.current) {
        const { clientWidth } = ref.current;

        setHeight((clientWidth / 16) * 9);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight, { passive: true });

    return () => window.removeEventListener("resize", updateHeight);
  }, [ref]);

  const videoId = getYoutubeVideoId(youtubeVideo);

  if (!videoId) return <></>;

  return (
    <Wrapper ref={ref} defaultView={!showVideo} style={{ height }}>
      {showVideo ? (
        <YTVideo
          videoId={videoId}
          opts={{
            width: "100%",
            height: height,
            playerVars: {
              controls: 0,
              autoplay: 0,
              showinfo: 0,
            },
          }}
          onReady={(event) => {
            event.target.playVideo();
          }}
        />
      ) : (
        <>
          <Image
            src={`https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`}
            cover
          />
          <PlayButton onClick={() => setShowVideo(true)}>
            <PlayIcon />
          </PlayButton>
        </>
      )}
    </Wrapper>
  );
};

export { YoutubeVideo };
