import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const _UnorderedList = styled.ul`
  width: 100%;
  counter-reset: list;
  margin: 0;
  padding: 16px 0 30px 0;

  & > li {
    width: 100%;
    display: flex;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.EYOffBlack.default};
    font-weight: 300;
    margin: 32px 0 0 0;

    &:first-child {
      margin: 0;
    }

    &:before {
      counter-increment: list;
      content: "";
      display: block;
      flex-shrink: 0;
      width: 4px;
      height: 4px;
      margin-top: 10px;
      margin-right: 16px;
      background-color: ${theme.colors.EYYellow.default};
    }

    & > ul,
    & > ol {
      padding: 24px 0 0 48px !important;
    }
  }
`;

const UnorderedList = ({ children }) => (
  <_UnorderedList>{children}</_UnorderedList>
);

export default UnorderedList;
